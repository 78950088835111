import { useEffect, useState, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { history } from "../../index";
import { logoutAction } from '../../actions/auth/authActions';
import { toast } from 'react-toastify';
import api from "../commonGetToken/CommonGetToken";
import { useSelector, shallowEqual } from 'react-redux';

export default function IdleTimer() {
  const [idleState, setIdleState] = useState('Active');
  const [count, setCount] = useState(0);
  const currUser = useSelector((state) => state.curr_user, shallowEqual);
  const [allUsers, setAllUsers] = useState([]);
  const [autoLogoutTIme, setAutoLogoutTIme] = useState(null);
  const [remaining, setRemaining] = useState(0);
  const idleTimeOut = window.globalTS.idle_time_out;
  const apiURL = window.globalTS.BACKEND_API + "/user";

  const onIdle = () => {
    if (history.location.pathname !== "/login") {
      setIdleState('Idle');
      localStorage.setItem("idleState", 'idle');
      const isIdle = localStorage.getItem("idleState");
      if (isIdle === 'idle') {
        document.addEventListener('click', handleClick);
      }
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await api.get(`${apiURL}/users`);
      setAllUsers(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, [])
  useEffect(() => {
    if (currUser && allUsers.length > 0) {
      const user = allUsers.find(user => user.employee_code === currUser.employee_code);
      if (user) {
        setAutoLogoutTIme(user.auto_logout_time);
      }
    }
  }, [currUser, allUsers]);

  const onActive = () => {
    setIdleState('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: autoLogoutTIme !== null ? 1000 * 60 * autoLogoutTIme : idleTimeOut,
    throttle: 500,
  });

  useEffect(() => {
    if (autoLogoutTIme !== null) {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
      return () => {
        clearInterval(interval);
        document.body.removeEventListener('click', handleClick);
      };
    }
  }, [autoLogoutTIme, getRemainingTime]); // Depend on getRemainingTime to keep the interval in sync

  const handleClick = (event) => {
    const { target } = event;
    if (target.matches('span, img, button, a, input')) {
      let idleTimeInMinutes = autoLogoutTIme !== null ? 1000 * 60 * autoLogoutTIme : idleTimeOut;
      toast.error(`User has been inactive for ${idleTimeInMinutes / 60000} minutes`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      logoutAction();
      history.push('/login');
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  if (autoLogoutTIme === null) {
    return null; // or some loading indicator
  }

  return null;
}
