import * as types from "../../types/actionTypes";
const reset_password = "/user/reset-password/";
import api from "../../components/commonGetToken/CommonGetToken"
import { encryptedPassword } from "../../components/auth/EncryptedPassword";

let url = window.globalTS.BACKEND_API;

const isResetingPassword = () => ({ type: types.IS_RESETING_PASSWORD });
const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS
});
const resetPasswordFailure = err => ({
  type: types.RESET_PASSWORD_FAILURE,
  err
});

function resetPassword(data) {
  return async function (dispatch) {
    try {
      dispatch(isResetingPassword());
      const formData = new FormData();
      formData.append('user_id', data.user_id);
      formData.append('password', encryptedPassword(data.password));
      const response = await api.post(url + reset_password, formData);
      if (response.status === 200) {
        dispatch(resetPasswordSuccess());
      }
      return response.data;
    } catch (err) {
      return dispatch(resetPasswordFailure(err));
    }
  };
}

export { resetPassword };
