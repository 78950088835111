import * as types from "../../types/actionTypes";
import axios from "axios";
import api from "../../components/commonGetToken/CommonGetToken"
let url = window.globalTS.BACKEND_API;
import { encryptedPassword } from "../../components/auth/EncryptedPassword";

const isChangingPassword = () => ({ type: types.IS_CHANGING_PASSWORD });

const changePasswordSuccess = (response) => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
  response
});

const changePasswordFailure = err => ({

  type: types.CHANGE_PASSWORD_FAILURE,
  err,
});

function changePassword(data) {
  return async function (dispatch) {
    try {
      dispatch(isChangingPassword());
      const formData = new FormData();
      formData.append('current_password', encryptedPassword(data.current_password));
      formData.append('new_password', encryptedPassword(data.new_password));
      formData.append('re_new_password', encryptedPassword(data.re_new_password));
      const response = await api.post(`${url}/auth/users/set_password/`, formData)
      return response;
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // Handle 400 response here
        return err.response.data; // You can return the response data or perform other actions
      }
      dispatch(changePasswordFailure(err));
      return dispatch(changePasswordFailure(err));
    }
  };
}

export { changePassword };
