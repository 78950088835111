import { Route, Redirect } from "react-router-dom";
import { CommonGetToken } from "../components/commonGetToken/CommonGetToken";

import React from "react";

const   checkAuth = () => {
  const token = CommonGetToken();
  if (!token || token == "undefined" || token == undefined) {
    localStorage.removeItem("jwt_token")
    return false;
  }
  return true;
};

const PrivateRoute = ({ component: Component }, ...rest) => (
  <Route
    {...rest}
    render={props => {
      const testbedName = props.location.search=== "" ?"":props.location.search.toString().split("?")[1];
      return checkAuth()  ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" , state: {Testbed:testbedName}}} />
      );
    }}
  />
);

const AuthenticatedRoute = ({ component: Component }, ...rest) => (
  <Route
    {...rest}
    render={props => {
      return checkAuth() ? (
        <Redirect to={{ pathname: "/" }} />
      ) : (
        <Component {...props} />
      );
    }}
  />
);

export { PrivateRoute, AuthenticatedRoute };
