import { logoutAction } from "../actions/auth/authActions";
import jwtDecode from "jwt-decode";
import * as types from "../types/actionTypes";
import { CommonGetToken } from "../components/commonGetToken/CommonGetToken"

// Reads jwtToken from cookie and renews it or logs the user out.

function refreshAuthToken({ dispatch, getState }) {
  return (next) => (action) => {
    if (typeof action === "function" || action.type === types.REFRESH_TOKEN) {
      if (CommonGetToken() && localStorage.length > 0) {
        const tokenExpiration = jwtDecode(CommonGetToken()).exp;
        const currentTime = Math.round(new Date().getTime() / 1000);
        const timeLeft = tokenExpiration - currentTime;
        const loginToken = CommonGetToken();
        if (tokenExpiration && timeLeft <= 0 || loginToken == "undefined") {
          logoutAction();
          return next(action);
        }
        return next(action);
      }
      return next(action);
    } else {
      return next(action);
    }
  };
}

export default refreshAuthToken;
