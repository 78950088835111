import React, { Component } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField, Button, Container, Paper, Box, Typography, Grid, ThemeProvider, createTheme } from "@material-ui/core";
import { blue, purple } from "@material-ui/core/colors";
import logo from "../../Images/daksh_iiot logo.png";
import { withFormik } from 'formik';
import { withStyles } from "@material-ui/styles";
import { styles } from "../Login/customStylesMui";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import api from "../commonGetToken/CommonGetToken"
import { encryptedPassword } from "./EncryptedPassword"

toast.configure();

class ResetOtherUsersPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
    };
    this.setFieldValue = props.setFieldValue
  }

  componentDidMount() {
    this.getUsersList();
  }

  getUsersList = () => {
    let url = window.globalTS.BACKEND_API;
    api.get(`${url}/user/getusers/`)
      .then((response) => {
        this.setState({ usersList: response.data.data });
      }).catch((error) => {
        console.log(error);
      });
  }


  render() {
    const {
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;
    const { usersList } = this.state
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: blue,
            secondary: purple,
          },
        })}
      >
        <div style={{ backgroundColor: "rgb(137 143 150)", height: "94vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Container component="main" style={{ maxWidth: "480px" }}>
            <Paper elevation={3}>
              <Box
                sx={{
                  px: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    Reset Password
                  </Typography>
                  <div
                    container
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end", marginBottom: "1rem"
                    }}
                  >
                    <img src={logo} style={{ height: "40px" }}></img>
                  </div>
                </div>{" "}
                <Box />
                <Box sx={{ mb: 2 }}>
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="user_id"
                          style={{ width: "400px" }}
                          select
                          name="user_id"
                          label="Select User"
                          value={values.user_id ?? ''}
                          onChange={handleChange}
                          helperText={touched.user_id && errors.user_id}
                          error={touched.user_id && Boolean(errors.user_id)}
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem key={''} value={''}>
                            Select User
                          </MenuItem>
                          {usersList?.map((item, index) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}

                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="password"
                          label="New Password"
                          type="password"
                          style={{ width: "400px" }}
                          id="password"
                          variant="outlined"
                          fullWidth
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.password && touched.password}
                          helperText={errors.password && touched.password && errors.password}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          style={{ width: "400px" }}
                          id="re_new_password"
                          name="re_new_password"
                          label="Repeat New Password"
                          type="password"
                          variant="outlined"
                          fullWidth
                          value={values.re_new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.re_new_password && touched.re_new_password}
                          helperText={errors.re_new_password && touched.re_new_password && errors.re_new_password}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="raised"
                          style={{ width: "50%" }}
                          type="button"
                        >
                          <Link to={'/'} > Cancel </Link>
                        </Button>
                        <Button
                          variant="contained"
                          style={{ width: "50%" }}
                          color="primary"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
            </Paper>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}
const EnhancedForm = withFormik({
  mapPropsToValues: () => ({
    user_id: "",
    password: ""
  }),
  validationSchema: yup.object({
    user_id: yup
      .string('Select user')
      .required('User is required'),
    password: yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain atleast 8 characters, one uppercase, one alowercase, one number and one special case character"
      ),
    re_new_password: yup.string()
      .oneOf([yup.ref("password"), null], "Passwords don't match.")
      .required("Confirm password is required"),
  }),
  handleSubmit: (
    { user_id, password },
    { props, setSubmitting, setErrors }
  ) => {
    props
      .resetPassword({ user_id, password })
      .then((res) => {
        window.scrollTo(0, 0),
          toast.success(res.message);
        setTimeout(() => {
          return props.history.push("/");
        }, 2000);
      });
    setSubmitting(false);
  },
  displayName: "ResetPasswordForm",
})(ResetOtherUsersPassword);

export const ResetPassword = withStyles(styles)(EnhancedForm);