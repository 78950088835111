import { AES, enc, mode, pad } from 'crypto-js';
export const encryptedPassword = (password) => {
    var key = enc.Utf8.parse('4512631236589784');
    var iv = enc.Utf8.parse('4512631236589784');
    var encrypted = AES.encrypt(enc.Utf8.parse(JSON.stringify(password)), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7
    });
    return encrypted.toString();
  };