import React, { Component } from "react";
import { withFormik } from "formik";
import * as yup from "yup";
import { withStyles } from "@material-ui/styles";
import { TextField, Button, Container, Paper, Box, Typography, Grid, ThemeProvider, createTheme } from "@material-ui/core";
import { styles } from "../Login/customStylesMui";
import { blue, purple } from "@material-ui/core/colors";
import logo from "../../Images/daksh_iiot logo.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class InnerPwForm extends Component {
  constructor(props) {
    super(props);
    this.setFieldValue = props.setFieldValue
  }

  render() {
    const {
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      handleReset,
      change_password,
    } = this.props;

    return (


      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: blue,
            secondary: purple,
          },
        })}
      >
        <div style={{ backgroundColor: "rgb(137 143 150)", height: "94vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Container component="main" maxWidth="xs">

            <Paper elevation={3}>
              <Box
                sx={{
                  px: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    Change Password
                  </Typography>
                  <div
                    container
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end", marginBottom: "1rem"
                    }}
                  >
                    <img src={logo} style={{ height: "40px" }}></img>
                  </div>
                </div>{" "}
                <Box
                />
                <Box sx={{ mb: 2 }}>
                  <form onSubmit={(e) => {
                    handleSubmit(e);
                  }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          name="current_password"
                          type="password"
                          fullWidth
                          variant="outlined"
                          value={values.current_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.current_password && touched.current_password}
                          helperText={
                            errors.current_password &&
                            touched.current_password &&
                            errors.current_password
                          }
                          label="Old Password"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="new_password"
                          type="password"
                          fullWidth
                          variant="outlined"
                          value={values.new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.new_password && touched.new_password}
                          helperText={
                            errors.new_password && touched.new_password && errors.new_password
                          }
                          label="New Password"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="re_new_password"
                          fullWidth
                          variant="outlined"
                          type="password"
                          value={values.re_new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.re_new_password && touched.re_new_password}
                          helperText={
                            errors.re_new_password &&
                            touched.re_new_password &&
                            errors.re_new_password
                          }
                          label="Repeat New Password"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="raised"
                          style={{ width: "50%" }}
                          type="button"
                        // onClick = {}
                        // disabled={!dirty || isSubmitting}
                        >
                          <Link to={'/'} > Cancel </Link>
                        </Button>
                        <Button
                          variant="contained"
                          style={{ width: "50%" }}
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </Grid>

                    </Grid>
                  </form>
                </Box>
              </Box>
            </Paper>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

const EnhancedForm = withFormik({
  mapPropsToValues: () => ({
    current_password: "",
    new_password: "",
    re_new_password: "",
  }),
  validationSchema: yup.object().shape({
    current_password: yup.string()
      .required("Old password is required"),
    new_password: yup.string()
      .required("New password is required")
      .when('current_password', (current_password, schema) => {
        return schema.test({
          test: new_password => new_password !== current_password,
          message: 'Old password and new password must be different'
        });
      })
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    re_new_password: yup.string()
      .oneOf([yup.ref("new_password"), null], "Password doesn't match.")
      .required("Confirm password is required"),
  }),
  handleSubmit: (
    { current_password, new_password, re_new_password },
    { props, setSubmitting, setErrors, resetForm }
  ) => {
    props
      .changePassword({ current_password, new_password, re_new_password })
      .then((res) => {
        if (res.new_password) {
          setErrors({})
          toast.error(res.new_password[0])
        }
        else if (res.non_field_errors) {
          setErrors({})
          toast.error(res.non_field_errors[0])
        }
        else if (res.current_password) {
          setErrors({})
          toast.error(res.current_password[0])
        }
        else if (res.status === 204) {
          window.scrollTo(0, 0),
            toast.success("Password changed successfully");
          setTimeout(() => {
            return props.history.push("/");
          }, 2000);
        }
      });
    setSubmitting(false);
  },
  displayName: "ChangePasswordForm",
})(InnerPwForm);

export const ChangePassword = withStyles(styles)(EnhancedForm);
