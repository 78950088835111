import axios from 'axios';
import { toast } from 'react-toastify';
import { logoutAction } from '../../actions/auth/authActions';
function CommonGetToken() {
    const token = localStorage.getItem("jwt_token")
    if (token) {
        return token
    }
    else {
        return false;
    }
}

const api = axios.create({
    // API base URL and other configurations if required
    baseURL: window.globalTS.BACKEND_API,
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
        config.headers.Authorization = `JWT ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    async (response) => {
        if (response.status === 401) {
            await this.handleUnauthorized(response, null);
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            document.body.addEventListener('click', handleClick);
        }
        return Promise.reject(error);
    },
);
const handleClick = (event) => {
    const { target } = event;

    if (target.matches('span, img, button,a,input')) {
        // The click event was triggered on a span, img, button,a,input or button element
        toast.error("Token expired", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
        });
        setTimeout(() => {
            logOff()
        }, 2000)
    }
};
function logOff() {
    localStorage.clear()
    window.location.href = "\login";
}

export default api;

export {
    CommonGetToken,
}
