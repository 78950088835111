import * as types from "../../types/actionTypes";
import { history } from "../../../src/index";
import { CommonGetToken } from "../../components/commonGetToken/CommonGetToken"
import api from "../../components/commonGetToken/CommonGetToken"
import { encryptedPassword } from "../../components/auth/EncryptedPassword";

let url = window.globalTS.BACKEND_API;

function authenticateAction(userData, dispatch, location, push) {
  return async function () {
    if (navigator.cookieEnabled) {
      if (userData.token != undefined) {
        localStorage.setItem("jwt_token", userData.token);
      } else {
        history.push('/login');
        localStorage.removeItem("jwt_token");
      }
    }

    if (location === "/login") {
      push("/");
    }
    return dispatch({ type: types.AUTHENTICATED });
  };
}
function registrationSuccessMessage() {
  return { type: types.REGISTRATION_SUCCESS_MESSAGE };
}

function registerAction(data) {
  return async function () {
    try {
      const formData = new FormData();
      formData.append('employee_code', data.employeeCode);
      formData.append('username', data.username);
      formData.append('email', data.email);
      formData.append('password', encryptedPassword(data.password));
      const response = await api.post(`${url}/user/users/`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}


function loginAction(data) {
  return async function () {

    let Testbed = localStorage.getItem("Testbed") === null ? "" : localStorage.getItem("Testbed");
    let formdata = new FormData();
    if (data.captcha_key != undefined && data.captcha_key != "") {
      formdata.append("username", data.username);
      formdata.append('password', encryptedPassword(data.password));
      formdata.append("captcha_value", data.captcha_value);
      formdata.append("captcha_key", data.captcha_key);
      formdata.append("test_bed_no", Testbed);
    }
    else {
      formdata.append("username", data.username);
      formdata.append('password', encryptedPassword(data.password));
      if (localStorage.getItem("Testbed") !== null && localStorage.getItem("Testbed") !== "" && localStorage.getItem("Testbed") !== "null") {
        formdata.append("test_bed_no", Testbed);
      }
    }

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    let response = await fetch(`${url}/auth/jwt/create/`, requestOptions);
    let responseJson = await response.json();
    return responseJson;
  };
}

// JWT tokens are not stored in our DB

function logoutAction() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `JWT ${CommonGetToken()}`);
  var formdata = new FormData();
  let Testbed = localStorage.getItem("Testbed");
  formdata.append("test_bed_no", Testbed);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  fetch(`${url}/user/logout/`, requestOptions)
    .then(response => response.text())
    .catch(error => console.log('error', error));
  localStorage.removeItem("jwt_token");
  localStorage.removeItem("shiftDetails");
  localStorage.removeItem("isLogin");
  localStorage.removeItem("Testbed");
  return { type: types.UNAUTHENTICATED };
}

function refreshToken() {
  return { type: types.REFRESH_TOKEN };
}

export {
  registerAction,
  loginAction,
  authenticateAction,
  logoutAction,
  registrationSuccessMessage,
  refreshToken
};
