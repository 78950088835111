import React from "react";
import { useState, useEffect, } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import welcomeimage from "./../Images/WelcomeScreen.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import api from "./commonGetToken/CommonGetToken";
import { CommonGetToken } from "./commonGetToken/CommonGetToken";
import { logoutAction } from "../actions/auth/authActions";

const useStyles = makeStyles({
  home: {
    height: "94vh",
    width: "100vw",
    display: "flex",
  },
  iframe: {
    position: "relative",
    "pointer-events": "auto",
    top: "-50px",
    left: "-68px",
    width: "calc(68px + 100%)",
    height: "calc(50px + 100%)",
  },
  iframeWithCrop: {
    position: "relative",
    top: "-50px",
    left: "-68px",
    "pointer-events": "none",
    width: "calc(68px + 100%)",
    height: "calc(50px + 100%)",
  },
  iframeWithPointEvent: {
    position: "relative",
    "pointer-events": "auto",
    width: "100%",
    height: "100%",
  },
  iframeWithPointEventNoneWithoutCrop: {
    position: "relative",
    "pointer-events": "none",
    width: "100%",
    height: "100%",
  },
  hideDashboardName: {
    left: "-68px",
    width: "calc(68px + 100%)",
    height: "100%",
    WebkitClipPath: 'polygon(0% 6%, 0% 20%, 0% 0%, 0% 100%, 0% 100%, 50% 100%, 100% 100%, 100% 0%, 70% 0%, 100% 0%, 32% 0%, 32% 6%)'
  },
  fullscreen: {
    width: "100%",
    height: "100%",
  },
  container: {
    scrollbarWidth: "none",
    width: "auto",
    backgroundColor: "rgb(248, 249, 250)",
    paddingRight: "1rem",
    overflowY: "auto",
  },

  toggle: {
    width: "155px",
    height: "auto",
    "background-color": "transparent",
    borderRadius: "12px 12px 12px 12px",
    color: "#010910",
    cursor: "pointer",
    background: "linear-gradient(to right, #e9ecef 50%, transparent 50%)",
    backgroundSize: "200% 100%",
    backgroundPosition: "right bottom",
    transition: "all .5s ease-out",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    textAlign: "start",
    flexDirection: "row-reverse",
    "&:hover": {
      backgroundPosition: "left bottom",
    },
  },
  activeSidebarItem: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
  },
});
const dashboardUrl = window.globalTS.BACKEND_API + "/dashboard";
const userUrl = window.globalTS.BACKEND_API + "/user";
const preventAutoLogOutOnRefresh = window.globalTS.preventAutoLogOutOnRefresh;

const HomePage = ({ registration_message }) => {
  if (window.location.search !== "") {
    localStorage.setItem("Testbed", window.location.search.split("?")[1])
    window.location.href = '/';
  }
  /*********************************************************** Hooks ********************************************************* */
  const classes = useStyles();
  const authenticated = useSelector((state) => state.auth.authenticated);

  /*********************************************************** States ********************************************************* */
  const [currUser, setcurrUser] = useState({
    username: "",
    parent_name: "",
    is_root: "",
    is_admin: "",
    is_employee: "",
    is_manager: "",
    is_approved: "",
  });
  const [sentRequestNotAccepted, setSentRequestNotAccepted] = useState(false);
  const [needToSendReq, setNeedToSendReq] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [value, setValue] = useState({
    is_admin: false,
    is_root: false,
    is_manager: false,
    is_employee: false,
  });
  const [selectedItem, setSelectedItem] = useState(0);
  const [dashboards, setDashboards] = useState([]);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const [isFetchingDashboards, setIsFetchingDashboards] = useState(false);
  const [areDashboardsFetched, setAreDashboardsFetched] = useState(false);
  const [isInitializingForm, setIsInitializingForm] = useState(false);
  const [contentURL, setContentURL] = useState("");
  const [isCrop, setIsCrop] = useState(true);
  const [IsOpen, setIsOpen] = useState(false);
  const [reactToPointerEvents, setReactToPointerEvents] = useState(false);
  const [hideDashboardName, setHideDashboardName] = useState(false);
  const handle = useFullScreenHandle();
  /*********************************************************** Body ********************************************************* */
  /*********************************************************** UseEffects ********************************************************* */

  useEffect(async () => {
    const handleUnload = (event) => {
      console.log("event", event.target.value)
      event.preventDefault();
      logoutAction();
    };
    if (preventAutoLogOutOnRefresh) {
      window.addEventListener('unload', handleUnload);
    }

    return () => {
      window.removeEventListener('unload', handleUnload);
    };

  }, []);

  useEffect(() => {
    if (!currUser.is_approved) {
      if (!currUser.is_admin && !currUser.is_manager && !currUser.is_employee) {
        setNeedToSendReq(true);
        setSentRequestNotAccepted(false);
      } else {
        setSentRequestNotAccepted(true);
        setNeedToSendReq(false);
      }
    } else {
      setNeedToSendReq(false);
      setSentRequestNotAccepted(false);
    }

  }, [isFetchingUser]);

  useEffect(async () => {
    setIsFetchingUser(true);
    if (authenticated) {
      let url = `${userUrl}/name/`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `JWT ${CommonGetToken()}`,
        },
      });
      setcurrUser({
        username: res.data.username,
        parent_name: res.data.parent_name,
        is_suepruser: res.data.is_suepruser,
        is_admin: res.data.is_admin,
        is_root: res.data.is_root,
        is_manager: res.data.is_manager,
        is_employee: res.data.is_employee,
      });
      setIsFetchingUser(false);
      setIsInitializingForm(true);
      let prevStateSelected = localStorage.getItem("stateSelected");
      if (prevStateSelected) {
        handleStateSelected({ target: { value: prevStateSelected } });
        // Remaining logic in useEffect as it requires stateSelected to be updated first
      } else {
        setIsInitializingForm(false);
      }
    }
  }, [refresh, authenticated]);

  useEffect(async () => {
    setIsFetchingDashboards(true);
    var url = `${dashboardUrl}/dashboards/`;
    const resp = await api.get(url).catch((err) => console.log(err));
    if (resp) {
      let newDashboards = resp.data;
      setDashboards(newDashboards);
    } else {
      setDashboards([]);
    }
    setIsFetchingDashboards(false);
    setAreDashboardsFetched(true);
  }, [refresh, authenticated]);

  const testbedNo = localStorage.getItem("Testbed");
  const topDashboardArray = dashboards.filter((dashboard) => dashboard.depth == 0 && dashboard.users.find(user => user == currUser.username) != undefined && dashboard.customer_name === null);
  let topLevelDashboards = (testbedNo !== null && testbedNo !== "undefined" && testbedNo !== "null" && testbedNo !== "") ? dashboards.filter((dashboard) => dashboard.depth == 0 && dashboard.users.find(user => user == currUser.username) != undefined && dashboard.customer_name == testbedNo) : dashboards.filter((dashboard) => dashboard.depth == 0 && dashboard.users.find(user => user == currUser.username) != undefined);
  if (topDashboardArray.length > 0) {
    topDashboardArray.forEach((dash) => {
      const newDashboard = topLevelDashboards.filter((dashboard) => dashboard.name === dash.name);

      if (newDashboard.length === 0) {
        topLevelDashboards.push(dash);
      }

    })
  }
  topLevelDashboards = [...topLevelDashboards].sort((a, b) => a.position - b.position);
  const firstDashboard = topLevelDashboards.find(dashboard => dashboard.position === 1.0);

  useEffect(() => {
    if (firstDashboard) {
      handleDashboardClick(firstDashboard);
    }
  }, [firstDashboard]);
  /*********************************************************** Functions ********************************************************* */
  const handleDashboardClick = (dashboard) => {
    setSelectedItem(dashboard.id);
    setContentURL(dashboard.content_url);
    setIsCrop(dashboard.is_crop);
    setHideDashboardName(dashboard.hide_dashboard_path);
    setReactToPointerEvents(dashboard.react_to_pointer_events);
  };
  /*********************************************************** Render Function ********************************************************* */
  const renderContent = () => {
    return (
      <div
        handle={handle}
        fixed="true"
        style={{ height: "94vh", width: "100vw", position: "relative", backgroundColor: 'rgb(244 245 245)' }}
        id="imagediv"
      >
        {areDashboardsFetched && dashboards.length == 0 && (
          <Alert severity="info">No dashboards configured</Alert>
        )}
        {!areDashboardsFetched && dashboards.length == 0 && (
          <Alert severity="info">Please select a dashboards</Alert>
        )}
        {contentURL !== "" && (
          <FullScreen handle={handle} className={classes.fullscreen}>
            {(isCrop) ? (
              reactToPointerEvents ? (
                <div className={classes.iframe}>
                  <iframe id="iframe-content" src={contentURL} frameBorder="0" style={{ backgroundColor: "#fff" }} />
                </div>
              ) : (
                <div className={classes.iframeWithCrop}>
                  <iframe id="iframe-content" src={contentURL} frameBorder="0" style={{ backgroundColor: "#fff" }} />
                </div>
              )
            ) : (
              (hideDashboardName) ? (
                reactToPointerEvents ? (
                  <div className={`${classes.iframeWithPointEvent} ${classes.hideDashboardName}`}>
                    <iframe id="iframe-content" src={contentURL} frameBorder="0" style={{ backgroundColor: "#fff" }} />
                  </div>
                ) : (
                  <div className={`${classes.iframeWithPointEventNoneWithoutCrop} ${classes.hideDashboardName}`}>
                    <iframe id="iframe-content" src={contentURL} frameBorder="0" style={{ backgroundColor: "#fff" }} />
                  </div>
                )
              ) : (
                reactToPointerEvents ? (
                  <div className={classes.iframeWithPointEvent}>
                    <iframe id="iframe-content" src={contentURL} frameBorder="0" style={{ backgroundColor: "#fff" }} />
                  </div>
                ) : (
                  <div className={classes.iframeWithPointEventNoneWithoutCrop}>
                    <iframe id="iframe-content" src={contentURL} frameBorder="0" style={{ backgroundColor: "#fff" }} />
                  </div>
                )
              )
            )}



          </FullScreen>
        )}
        {contentURL == "" && (
          <FullScreen handle={handle} className={classes.fullscreen}>
            <img
              src={welcomeimage}
              style={{ width: "100%", height: "100%" }}
            ></img>
          </FullScreen>
        )}
        <div className="screensize">
          <div className="screensizediv">
            <h6 className="tooltips">Full screen </h6>
            <button className="screensizebtn" onClick={handle.enter}>
              <FullscreenIcon className="screensizeicon" fontSize="large" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  // ----*******----------------********************88---------------------
  const openlist = () => {
    setIsOpen(!IsOpen);
    {
      IsOpen == false &&
        ((document.getElementById("datalist").style.display = "none"),
          (document.getElementById("dashboard").style.width = "2.7rem"),
          (document.getElementById("imagediv").style.width = "100vw"),
          (document.getElementById("heading").style.display = "none"));
    }
    {
      IsOpen == true &&
        ((document.getElementById("datalist").style.display = "flex"),
          (document.getElementById("dashboard").style.width = "auto"),
          (document.getElementById("heading").style.display = "block"));
    }
  };

  const renderTopicPannel = () => {
    return (
      <div className={classes.container} id="dashboard" style={{ position: "relative", zIndex: "1000", width: "auto" }}>
        <div className="expandicondiv">
          <h4
            id="heading"
            style={{
              textAlign: "center",
              marginBottom: "10px",
              marginLeft: "10px",
              color: "#010910",
              fontVariant: "all-petite-caps",
            }}
          >
            Dashboard
          </h4>
          <button className="expandbtn" onClick={openlist}>
            {IsOpen == true && <ArrowForwardIosIcon />}
            {IsOpen == false && <ArrowBackIosIcon />}
          </button>
        </div>
        <div id="datalist">
          {topLevelDashboards.map((topLevelDashboard, i) => {
            const childDashboardArray = dashboards.filter((dashboard) => dashboard.parent_id == topLevelDashboard.id && dashboard.users.find(user => user == currUser.username) != undefined && dashboard.customer_name === null);
            const children = (dashboards.filter((dashboard) => dashboard.customer_name !== null) && testbedNo !== null && testbedNo !== "undefined" && testbedNo !== "null" && testbedNo !== "") ? dashboards.filter(
              (dashboard) => dashboard.parent_id == topLevelDashboard.id && dashboard.users.find(user => user == currUser.username) != undefined && dashboard.customer_name == testbedNo) : dashboards.filter(
                (dashboard) => dashboard.parent_id == topLevelDashboard.id && dashboard.users.find(user => user == currUser.username) != undefined);
            if (childDashboardArray.length > 0) {
              childDashboardArray.forEach((dash) => {
                const newDashboard = children.filter((dashboard) => dashboard.name === dash.name);
                if (newDashboard.length === 0) {
                  children.push(dash)
                }

              })
            }
            return (
              <div className="level1">
                <label
                  key={topLevelDashboard.id}
                  for={"btn-" + topLevelDashboard.id}
                  className={`${classes.toggle} ${selectedItem === topLevelDashboard.id ? classes.activeSidebarItem : ""}`}
                  onClick={(e) => {
                    handleDashboardClick(topLevelDashboard)
                  }}
                >
                  {topLevelDashboard.name}

                  <ArrowDropDownIcon
                    className={children.length == 0 ? "arrowlevel3" : "arrow"}
                  />
                </label>
                <input
                  className="dashboardinput"
                  type="checkbox"
                  id={"btn-" + topLevelDashboard.id}
                ></input>
                <ul className="level2" style={{ listStyleType: "none" }}>
                  {children.map((child, i) => {
                    const grandChildDashboardArray = dashboards.filter((dashboard) => dashboard.parent_id == child.id && dashboard.users.find(user => user == currUser.username) != undefined && dashboard.customer_name === null);
                    const grandchildren = (dashboards.filter((dashboard) => dashboard.customer_name !== null) && testbedNo !== null && testbedNo !== "undefined" && testbedNo !== "null" && testbedNo !== "") ? dashboards.filter(
                      (dashboard) => dashboard.parent_id == child.id && dashboard.users.find(user => user == currUser.username) != undefined && dashboard.customer_name == testbedNo
                    ) : dashboards.filter(
                      (dashboard) => dashboard.parent_id == child.id && dashboard.users.find(user => user == currUser.username) != undefined);

                    if (grandChildDashboardArray.length > 0) {
                      grandChildDashboardArray.forEach((dash) => {
                        const newDashboard = grandchildren.filter((dashboard) => dashboard.name === dash.name);
                        if (newDashboard.length === 0) {
                          grandchildren.push(dash)
                        }

                      })
                    }

                    return (
                      <li>
                        <li style={{ marginTop: "5px" }}>
                          <label
                            for={"btn-" + child.id}
                            className={`${classes.toggle} ${selectedItem === child.id ? classes.activeSidebarItem : ""}`}
                            onClick={(e) => {
                              handleDashboardClick(child)
                            }}
                          >
                            {child.name}

                            <ArrowDropDownIcon
                              className={
                                grandchildren.length == 0
                                  ? "arrowlevel3"
                                  : "arrow"
                              }
                            />
                          </label>
                        </li>
                        <input
                          className="dashboardinput"
                          type="checkbox"
                          id={"btn-" + child.id}
                        />
                        <ul className="level3">
                          {grandchildren.map((supchild, i) => {
                            return (
                              <li
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  className={`${classes.toggle} ${selectedItem === supchild.id ? classes.activeSidebarItem : ""}`}
                                  onClick={(e) => {
                                    handleDashboardClick(supchild)
                                  }}
                                >
                                  {supchild.name}
                                  <ArrowDropDownIcon className="arrowlevel3" />
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      {registration_message && (
        <div className="alert alert-info text-center mt-4" role="alert">
          <strong>{registration_message}</strong>
        </div>
      )}
      {!isFetchingUser && (
        <div className={classes.home}>
          {renderTopicPannel()}
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default HomePage;
