import React, { useState } from "react";
import axios from 'axios'
import { withFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import "./style.css";
import { withStyles } from "@material-ui/styles";
import { useSelector, shallowEqual } from "react-redux";
import api from "../../../src/components/commonGetToken/CommonGetToken";
import {
  TextField,
  Button,
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  createTheme,
  ThemeProvider,
  MenuItem
} from "@material-ui/core";
import { blue, purple } from "@material-ui/core/colors";
import { styles } from "../Login/customStylesMui";
import logo from "../../Images/daksh_iiot logo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./style.css";
import { encryptedPassword } from "./EncryptedPassword";


toast.configure();

const alerttoast = (name) => {
  toast.success(name);
};

const url = window.globalTS.BACKEND_API;

function InnerRegistrationForm(props) {
  const history = useHistory();
  const currUser = useSelector((state) => state.curr_user, shallowEqual);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  const GoBackScreen = () => {
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            px: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          style={{ height: "89vh" }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4" color="error" align="center">
                Unable to Access
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" align="center" variant="outlined" fullWidth to="/" component={Link}>
                Go back
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  };

  const userPermissions = currUser.is_superuser === true ? ["Root", "Admin", "Manager", "Employee"] : currUser.is_root === true ? ["Admin", "Manager", "Employee"]
    : currUser.is_admin === true ? ["Manager", "Employee"] : currUser.is_manager === true ? ["Employee"] : null

  if (!currUser.is_admin && !currUser.is_manager && !currUser.is_root) {
    return <GoBackScreen />;
  }

  const isEnabled = values.username.length > 0 && values.email.length > 0 && values.password1.length > 0 && values.password2.length > 0;
  const isRoleEnable = values.username.length > 0 && values.email.length > 0 && values.password1.length > 0 && values.password2.length > 0 && values.role.length > 0;

  return (
    <>
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: blue,
            secondary: purple,
          },
        })}
      >
        <div style={{ backgroundColor: "rgb(137 143 150)", height: "94vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Container component="main" maxWidth="xs"
            style={{
              minWidth: "35%"
            }}>
            <Paper

              elevation={3}>
              <Box
                sx={{
                  px: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    Create A User
                  </Typography>
                  <div
                    container
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end", marginBottom: "1rem"
                    }}
                  >
                    <img src={logo} style={{ height: "40px" }}></img>
                  </div>
                </div>{" "}
                <Box
                />
                <Box sx={{ mb: 2 }}>
                  <form onSubmit={(e) => {
                    handleSubmit(e);
                  }}>
                    <Grid container spacing={3}>
                      <div
                        style={{
                          width: "95%",
                          display: "flex",
                          margin: "5px 5px 10px 10px"
                        }}>
                        <Grid item xs={12}
                          style={{
                            marginRight: "15px"
                          }}>
                          <TextField
                            name="employeeCode"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={values.employeeCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.employeeCode && touched.employeeCode}
                            helperText={
                              errors.employeeCode && touched.employeeCode && errors.employeeCode
                            }
                            label="Employee Code"
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="username"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.username && touched.username}
                            helperText={
                              errors.username && touched.username && errors.username
                            }
                            label="Username"
                            required
                          />
                        </Grid>
                      </div>
                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email && touched.email}
                          helperText={
                            errors.email && touched.email && errors.email
                          }
                          label="Email Address"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="password1"
                          type="password"
                          fullWidth
                          variant="outlined"
                          value={values.password1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.password1 && touched.password1}
                          helperText={
                            errors.password1 &&
                            touched.password1 &&
                            errors.password1
                          }
                          //className={classes.textField}
                          label="Password"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="password2"
                          type="password"
                          value={values.password2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.password2 && touched.password2}
                          helperText={
                            errors.password2 &&
                            touched.password2 &&
                            errors.password2
                          }
                          //className={classes.textField}
                          label="Repeat your Password"
                          required
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          variant="outlined"
                          name="role"
                          select={true}
                          label="Role"
                          value={values.role}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {userPermissions.map((item, index) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="raised"
                          style={{ width: "50%" }}
                          type="button"
                        >
                          <Link to={'/'} > Cancel </Link>
                        </Button>
                        <Button
                          style={{ width: "50%" }}
                          variant="contained"
                          color="primary"
                          disabled={!isRoleEnable}
                          type="submit"
                        // onClick={handleTimedSubmit}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
            </Paper>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
}
const EnhancedForm = withFormik({
  mapPropsToValues: () => ({
    employeeCode: "",
    username: "",
    email: "",
    password1: "",
    password2: "",
    role: "",
  }),

  validationSchema: Yup.object().shape({
    employeeCode: Yup.string()
      .required("Employee code is required")
      .matches(/^[0-9a-zA-Z]*$/, "Employee code must be alpha numeric"),
    username: Yup.string()
      .required("Username is required"),
    password1: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Z]).{8,32}$/,
        "Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    password2: Yup.string()
      .oneOf([Yup.ref("password1"), null], "Passwords don't match")
      .required("Confirm password is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    role: Yup.string()
      .required("Role is required"),
  }),

  handleSubmit: (
    { employeeCode, username, email, password1, role },
    { props, setSubmitting, setErrors }
  ) => {
    props
      .registerAction({ employeeCode, username, email, password: password1 })
      .then((resp) => {
        if (resp.non_field_errors || Array.isArray(resp.employeeCode) || Array.isArray(resp.username) ||
          Array.isArray(resp.email) || Array.isArray(resp.password)) {
          setErrors(resp);
        }
        else {
          const requestOptions = {
            data: ({
              empolyee_code: employeeCode,
              username: username,
              is_root: role == "Root",
              is_admin: role == "Admin",
              is_manager: role == "Manager",
              is_employee: role == "Employee",
            }),
          };
          api.post(`${url}/user/role/`, requestOptions.data)
            .then((response) => {
              if (response.status === 200) {
                alerttoast("User created successfully");
                setTimeout(() => {
                  return props.history.push("/");
                }, 2000);
              } else {
                response.data.then((data) => {
                  toast.error(data.message);
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    setSubmitting(false);
  },
  displayName: "RegistrationForm", // helps with react devtools
})(InnerRegistrationForm);
export const Register = withStyles(styles)(EnhancedForm);