import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
require("dotenv").config();
import {
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import axios from "axios";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
import { store } from "./store";
import { AuthenticatedRoute, PrivateRoute } from "./customRoutes/ProtectedRoutes";
import Navigation from "./containers/NavigationContainer";
import HomePage from "./containers/HomePageContainer";
import Login from "./containers/auth/LoginContainer";
import Register from "./containers/auth/RegisterContainer";
import ChangePassword from "./containers/auth/ChangePasswordContainer";
import ResetPassword from "./containers/auth/ResetPasswordContainer";
import CheckUserIdleTime from "./components/CheckUserIdleTime/IdleTimer"
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
export const history = createHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <div>
        <PrivateRoute component={CheckUserIdleTime} />
        <PrivateRoute exact path="/" component={Navigation} />
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/home" component={HomePage} />
          <AuthenticatedRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/register" component={Register} />
          <PrivateRoute exact path="/reset-user-password" component={ResetPassword} />
          <Route exact path="/signout" render={() => <Redirect to="/login" />} />
          <PrivateRoute exact path="/changepassword" component={ChangePassword} />
        </Switch>
      </div>
    </Router>
  </Provider>,

  document.getElementById("root")
);
