import React, { Component } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/styles";
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import dakshii from "../../Images/daksh_iiot logo.png";
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Stack,
  Grid,
} from "@mui/material";

import "./style.css"
import axios from "axios";
import { encryptedPassword } from "./EncryptedPassword";
const url = window.globalTS.BACKEND_API;
const ShiftUrl = window.globalTS.ShiftUrl + "/get/shifts";
const testBedURL = window.globalTS.isTestBedURLPass

const customStyles = () => ({
  wlctxtLogin: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      color: "#121212",
      fontSize: "48px",
      fontFamily: "Inter",
      lineHeight: "58px",
      width: "222px",
      height: "58px",
    },
  },
  Logintxt: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: "32px",
      fontFamily: "Inter",
      lineHeight: "30px",
      fontStyle: "bold",
      color: "#D6543C",
      width: "380px",
      height: "38.73px",
    },
  },
  LoginUserbtn: {
    height: "50px",
    "&.MuiButtonBase-root": {
      backgroundColor: "#D6543C",
      color: "#fff",
      padding: "10px 20px 10px 20px",
      borderRadius: "4px",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "20px",
      fontFamily: "Inter",
      textTransform: "none",
      boxShadow: "none",
    },
    '&:hover': {
      backgroundColor: "#D6543C",
    },
  },
});

let isLogin = false;
class InnerLoginForm extends Component {
  constructor(props) {
    if (props.location.state !== undefined && props.location.state.Testbed !== "") {
      localStorage.setItem("Testbed", props.location.state.Testbed)
    }
    super(props);
    this.state = {
      captcha_image: "",
      enableCaptcha: false,
      showPassword: false,
      mobileno: "",
      mobileErr: "",
      password: "",
      passErr: "",
      invalid: false
    };
    this.setFieldValue = props.setFieldValue

  }

  componentDidMount() {

    if (!localStorage.getItem("isLogin")) {
      this.hideCaptcha()
      setTimeout(() => {
        this.generateCaptcha()
      }, 500);
    }

    setInterval(() => {
      if (localStorage.getItem("isLogin")) {
        this.shiftDetails();
      }
    }, window.globalTS.time);

    setInterval(() => {
      if (localStorage.getItem("isLogin")) {
        let shiftdetails = JSON.parse(localStorage.getItem("shiftDetails"));
        if (shiftdetails !== undefined && shiftdetails !== null) {
          shiftdetails.forEach((element) => {
            const date = new Date();
            let minutes = parseInt(date.getMinutes()) < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let hours = parseInt(date.getHours()) < 10 ? "0" + date.getHours() : date.getHours();
            const showTime = hours
              + ':' + minutes
              + ":" + "00";
            if (showTime === element.endTime) {
              localStorage.removeItem("jwt_token");
              localStorage.removeItem("shiftDetails");
              localStorage.setItem("isLogin", false);
              window.location.reload();
            }
          });
        }
      }
    }, window.globalTS.time)
  }

  generateCaptcha = async () => {
    try {
      if (this.state.enableCaptcha) {
        const response = await axios.post(`${url}/api/captcha/`)
        await this.setFieldValue("captcha_key", response.data.captcha_key)
        this.setState({ captcha_image: response.data.captcha_image })
      }
    } catch (err) {
      console.log(err);
    }
  }

  shiftDetails = async () => {
    try {
      const token = localStorage.getItem("jwt_token");
      const response = await axios.get(`${ShiftUrl}`, {
        headers: {
          'Authorization': `JWT ${token}`
        }
      })
      this.setState({ shiftDetails: response })
      localStorage.setItem("shiftDetails", JSON.stringify(response.data.data))
    }
    catch (err) {
      console.log(err);
    }
  }

  submitForm = async (e) => {

    const invalidCaptchaValue = this.props.errors?.captcha_key == 'Invalid captcha value';
    const unableToLogIn = this.props.errors?.captcha_key == "Unable to log in with provided credentials.";
    const expiredCaptchaKey = this.props.errors?.captcha_key == "Invalid or expired captcha key";

    await this.shiftDetails();

    if (invalidCaptchaValue || unableToLogIn || expiredCaptchaKey) {
      this.generateCaptcha();
    }


  }
  hideCaptcha = async () => {
    let response = await axios.get(`${url}/user/captcha/`);
    this.setState({ enableCaptcha: response.data.enable })
  }
  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      classes,
    } = this.props;


    const { captcha_image, enableCaptcha, showPassword } = this.state;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              marginTop: "150px",
              marginLeft: "136px",
              marginRight: "310px",
              width: "100%",
              maxWidth: "450px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "427px", height: "178" }}
              src={dakshii}
              alt="login"
            />
          </div>

          <Container
            style={{
              marginTop: "100px",
              marginRight: "171px",
            }}
            maxWidth="sm"
          >
            <div
              style={{
                color: "#D0D3D4",
                borderRadius: "10px",
                "&.Mui-checked": {
                  color: "#D6543C",
                },
              }}
            >
              <Typography
                className={classes.wlctxtLogin}
                variant="h4"
                component="h2"
                gutterBottom
              >
                Welcome
              </Typography>

              <Typography
                variant="h4"
                component="h2"
                className={classes.Logintxt}
                sx={{ mb: 8 }}
              >
                Login to your account
              </Typography>
              <Box
                component="form"
                onSubmit={(e) => {
                  handleSubmit(e);
                  setTimeout(() => {
                    this.submitForm(e)
                  }, 1000);
                }}
                noValidate
                autoComplete="off"
                className={classes.loginUserDiv}
              >
                <Stack spacing={3}>
                  <TextField
                    variant="outlined"
                    name="username"
                    type="text"
                    fullWidth
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.username && touched.username}
                    helperText={
                      errors.username && touched.username && errors.username
                    }
                    label="Username"
                  />
                  <TextField
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    label="Password"
                    value={values.password}
                    onChange={handleChange || this.setpasswordvalue}
                    onBlur={handleBlur}
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password && errors.password
                    }
                  />
                  {enableCaptcha ?
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '1rem'
                      }}>
                        <Grid item xs={12}>
                          <img src={`data:image/jpeg;base64,${captcha_image}`}
                            style={{
                              height: '3rem',
                            }}
                            alt="No captcha found"
                          />
                        </Grid>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '1rem',
                          marginRight: '1rem'
                        }}>
                          <Grid item xs={12}>
                            <div style={{
                              height: '28px',
                              width: '25px',
                              borderRadius: '15px',
                              backgroundColor: "#D6543C",
                              color: '#fff',
                            }}
                              onClick={() => this.generateCaptcha()}
                            >
                              <RefreshIcon />
                            </div>
                          </Grid>
                        </div>
                      </div>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="captcha_value"
                          type="captcha_value"
                          value={values.captcha_value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.captcha_value && touched.captcha_value}
                          helperText={errors.captcha_value && touched.captcha_value && errors.captcha_value}
                          label="Enter Captcha"
                        />
                      </Grid>
                    </div>
                    : ""
                  }
                </Stack>
                <br />
                <Button
                  type="submit"
                  className={classes.LoginUserbtn}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#D6543C",
                      boxShadow: "none",
                    },
                  }}
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Login
                </Button>
              </Box>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const EnhancedForm = withFormik({
  mapPropsToValues: () => ({
    username: "",
    password: "",
    captcha_value: "",
    captcha_key: "",
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string()
      .min(4, "The password must be at least 4 characters")
      .required("Password is required"),
  }),
  handleSubmit: (
    { username, password, captcha_value, captcha_key },
    { props, setSubmitting, setErrors }
  ) => {
    let testbed = localStorage.getItem("Testbed");
    if (captcha_key != undefined && captcha_key != "") {
      props.loginAction({ username, password, captcha_value, captcha_key, testbed }).then((response) => {
        if (testBedURL && testbed == null) {
          toast.error("Warning: You can not be logged in with out passing testbed", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
        else if (response.non_field_errors) {
          toast.error(response.non_field_errors[0], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          })
          /* To get error in submitForm()*/
          setErrors({ captcha_key: response.non_field_errors[0] })
        }
        else if (response.captcha_value) {
          setErrors({ captcha_value: response.captcha_value[0] })
        }
        else {
          props.authenticateAction(
            response,
            props.dispatch,
            props.location.pathname,
            props.history.push,
          );
          localStorage.setItem("isLogin", true);
        }
      });
    } else {
      props.loginAction({ username, password, testbed }).then((response) => {
        if (testBedURL && testbed == null) {
          toast.error("Warning: You can not be logged in with out passing testbed", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
        else if (response.non_field_errors) {
          toast.error(response.non_field_errors[0], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
          /* To get error in submitForm()*/
          setErrors({ captcha_key: response.non_field_errors[0] })
        }
        else if (response.captcha_value) {
          setErrors({ captcha_value: response.captcha_value[0] })
        }
        else {
          setErrors({})
          props.authenticateAction(
            response,
            props.dispatch,
            props.location.pathname,
            props.history.push,
          );
          localStorage.setItem("isLogin", true);
        }
      });

    }
    setSubmitting(false);
  },
  displayName: "LoginForm", //hlps with react devtools
})(InnerLoginForm);

export const Login = withStyles(customStyles)(EnhancedForm);
