import { NavLink as Link, useHistory, Route } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import React, { useState, useEffect } from "react";
import { AddCurrUser } from "../actions/user/userActions";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import logo from "../Images/daksh_iiot logo.png";
import api from "./commonGetToken/CommonGetToken";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const userUrl = window.globalTS.BACKEND_API + "/user";
const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function Navigation(props) {
  /********************************************************* Hooks ************************************************** */
  const classes = useStyles();
  var history = useHistory();
  const bull = <span className={classes.bullet}>•</span>;
  const currUser = useSelector((state) => state.curr_user, shallowEqual);
  const dispatch = useDispatch();
  /********************************************************* States ************************************************** */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [requests, setRequests] = useState([]);
  const [statusChanges, setStatusChanges] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isFetchingStatusChanges, setIsFetchingStatusChanges] = useState(true);
  const [isFetchingRequests, setIsFetchingRequests] = useState(true);
  const [isFetchingCurrUser, setIsFetchingCurrUser] = useState(true);
  /********************************************************* Body ************************************************** */
  var viewDashboardsBool = false;
  if (currUser.is_approved) {
    viewDashboardsBool = true;
  }

  /********************************************************* Functions ************************************************** */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleNavbarOnClick = () => {
    if (window.innerWidth <= 989) {
      return toggle();
    }
  };

  const clearStatusNotification = () => {
    setStatusChanges(0);
    toggleNavbarOnClick();
  };

  const getChip = () => {
    // if (currUser.is_approved) {
    if (currUser.is_superuser) {
      return (
        <Chip label="SU" style={{ marginLeft: "5px" }} color="secondary" />
      );
    }
    else if (currUser.is_root) {
      return (
        <Chip label="Root" style={{ marginLeft: "5px" }} color="secondary" />
      );
    }
    else if (currUser.is_admin) {
      return (
        <Chip label="Admin" style={{ marginLeft: "5px" }} color="secondary" />
      );
    }
    else if (currUser.is_manager) {
      return (
        <Chip label="Manager" style={{ marginLeft: "5px" }} color="secondary" />
      );
    }
    else if (currUser.is_employee) {
      return (
        <Chip
          label="Employee"
          style={{
            marginLeft: "5px",
            backgroundColor: "#008000",
            color: "#FFFFFF",
          }}
        />
      );
    } else {
      return (
        <Chip label="Viewer" style={{ marginLeft: "5px" }} color="primary" />
      );
    }
    // }
  };
  const getBellNotificaionAdmin = () => {
    if (currUser.is_approved) {
      if (currUser.is_admin) {
        return (
          <div >
            {currUser.is_admin && (
              <>
                <NavItem style={{ marginLeft: "100px" }}>
                  <IconButton component="span"></IconButton>
                </NavItem>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {requests.map((req, i) => {
                    return (
                      <div className={classes.root}>
                        <MenuItem >
                          <Card className={classes.root}>
                            <CardContent>
                              <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                              >
                                User: {req.username}
                              </Typography>
                              <Typography variant="h5" component="h2">
                                Staff Request
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<SaveIcon />}
                                onClick={() => {
                                  handleReq(req.username, "True");
                                  handleClose();
                                }}
                              >
                                Accept
                              </Button>

                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  handleReq(req.username, "False");
                                  handleClose();
                                }}
                              >
                                Reject
                              </Button>
                            </CardActions>
                          </Card>
                        </MenuItem>
                      </div>
                    );
                  })}
                  {requests.length == 0 && (
                    <MenuItem>No Pending Requests</MenuItem>
                  )}
                </Menu>
              </>
            )}
          </div>
        );
      }
    }
  };
  const userIsAuthenticatedEmail = () => {
    if (props.authenticated) {
      return (
        <div  >
          <UncontrolledDropdown
            nav
            className="nav-item dropdown"
            key="email-auth"

          >
            <DropdownToggle
              nav
              caret
              className="nav-link"
              style={{ color: "#343a40" }}
            >
              Account
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu"
              style={{
                backgroundColor: "black",
                maxWidth: "240px",
                marginLeft: "-10rem",
              }}
            >
              <Button
                variant="outlined"
                style={{ height: "40px", margin: "5px", textTransform: "none" }}
              >
                <span key="home">
                  <NavLink
                    onClick={toggleNavbarOnClick}
                    style={{ color: "white", margin: "0px", fontSize: "14px" }}
                  >
                    {currUser.username}
                    {getChip()}
                  </NavLink>
                </span>
              </Button>
              <Button
                variant="outlined"
                style={{ height: "40px", margin: "5px", textTransform: "none" }}
              >
                <span key="signout" onClick={props.logoutAction}>
                  <NavLink
                    tag={Link}
                    to="/signout"
                    onClick={toggleNavbarOnClick}
                    style={{ color: "white", margin: "0px", fontSize: "14px", textTransform: "none" }}
                  >
                    Log out
                  </NavLink>
                </span>
              </Button>

              {currUser.is_root || currUser.is_superuser || currUser.is_admin || currUser.is_manager ? (
                <Button
                  variant="outlined"
                  style={{ height: "40px", margin: "5px", textTransform: "none" }}
                >
                  <div>
                  <NavLink
                    tag={Link}
                    to="/register"
                    onClick={toggleNavbarOnClick}
                    style={{ color: "white", margin: "0px", fontSize: "14px", textTransform: "none" }}
                  >
                    Create User
                  </NavLink>
                  </div>
                </Button>) : null}

              {/* {currUser.is_admin || currUser.is_manager ? ( */}
              <Button
                variant="outlined"
                style={{ height: "40px", margin: "5px", textTransform: "none" }}
              >
                <NavLink
                  tag={Link}
                  to="/changepassword"
                  // key="sign-up"

                  onClick={toggleNavbarOnClick}
                  style={{ color: "white", margin: "0px", fontSize: "14px" }}
                >
                  Change Password
                </NavLink>
              </Button>
              {/* ): null} */}

              {!currUser.is_employee ? (
                <Button
                  variant="outlined"
                  style={{ height: "40px", margin: "5px", textTransform: "none" }}
                >
                  <NavLink
                    tag={Link}
                    to="/reset-user-password"
                    // key="sign-up"

                    onClick={toggleNavbarOnClick}
                    style={{ color: "white", margin: "0px", fontSize: "14px" }}
                  >
                    Reset Password
                  </NavLink>
                </Button>
              ) : null}


              {currUser.is_staff ? (
                <Button
                  variant="outlined"
                  style={{ height: "40px", margin: "5px", textTransform: "none" }}
                >
                  <a
                    href={window.globalTS.BACKEND_API + "/admin/"}
                    target="_blank"
                    style={{
                      color: "white",
                      marginLeft: "9px",
                      fontSize: "14px",
                      textDecoration: "none",
                    }}
                  >
                    Admin Panel
                  </a>
                </Button>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    }
  };
  const userIsNotAuthenticated = () => {
    if (!props.authenticated) {
      return <></>;
    }
  };

  const handleReq = async (username, payload) => {
    let url = `${userUrl}/approval/${username}/`;

    const formData = new FormData();
    formData.append("key", payload);
    try {
      const response = await api.post(url, formData);
    setRefresh(refresh + 1);
    } catch (error) {
      console.log(error);
    }
  };

  /********************************************************* useEffects ************************************************** */
  useEffect(async () => {
    setIsFetchingCurrUser(true);
    if (props.authenticated) {
      let url = `${userUrl}/name/`;
      const res = await api.get(url);
      dispatch(AddCurrUser(res.data));
      setIsFetchingCurrUser(false);
    }
  }, [props.authenticated]);

  useEffect(async () => {
    setIsFetchingRequests(true);
    if (props.authenticated) {
      let url = `${userUrl}/approval/`;
      const res = await api.get(url);
      setRequests([...res.data]);
      setIsFetchingRequests(false);
    }
    props.refreshToken();
    setInterval(function () {
      props.refreshToken();
    }, 900000);
  }, [refresh, props.authenticated]);

  /********************************************************* RenderFunction ************************************************** */
  return (
    <>
      <div>
        <Navbar
          color="faded"
          className="navbar navbar-toggleable-md navbar-inverse "
          expand="md"
          style={{ height: "6vh", backgroundColor: "#e9ecef", zIndex: "1000" }}
        >
          <NavbarBrand href="/">
            <img
              style={{
                width: "100px",
                height: "40px",
              }}
              src={logo}
            ></img>
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              toggle();
            }}
          />
          <Collapse
            isOpen={isOpen}
            navbar
            style={
              {
                width: "auto",
                marginLeft: "auto",
                marginRight: ".5rem",
                justifyContent: 'flex-end'
              }
            }>
            <Nav navbar >
              <NavItem >
                <NavLink
                  tag={Link}
                  to="/"
                  activeClassName="active"
                  exact
                  onClick={toggleNavbarOnClick}
                  nav ="true"
                  caret ="true"
                  className="nav-link"
                  style={{ color: "#343a40" }}
                >
                  Home
                </NavLink>
              </NavItem >
              {userIsNotAuthenticated()}
              {userIsAuthenticatedEmail()}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </>
  );
}
export default Navigation;
