import { combineReducers } from "redux";

import authReducer from "./auth/authReducer";
import changePasswordReducer from "./auth/changePasswordReducer";
import resetPasswordReducer from "./auth/resetPasswordReducer";
import dashboardReducer from "./dashboards/dashboardsReducer";
import currUserReducer from "./user/currUserReducer";
const rootReducer = combineReducers({
  auth: authReducer,
  change_password: changePasswordReducer,
  dashboard: dashboardReducer,
  curr_user: currUserReducer,
  reset_password:resetPasswordReducer
});

export default rootReducer;
